body {
  background: #ecedf0 !important;
}
.multi-form-wrap-outer * {
  box-sizing: border-box;
}

.multi-form-wrap-outer {
  color: #222;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.multi-form-wrapper {
  display: flex;
}

.multi-form-wrapper .section {
  width: 100%;
  padding: 0 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.nav-breadcrumbs-outer {
  padding: 20px 20px 0px 20px;
  max-width: 100%;
  overflow: auto;
  display: flex;
  width: 100%;
}

.nav-breadcrumbs {
  display: flex;
  border: solid 1px #ddd;
  background: #fff;
  height: 50px;
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
}

.nav-breadcrumbs a {
  text-decoration: none;
  color: #949494;
  position: relative;
  padding: 0 25px 0 20px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  white-space: nowrap;
  line-height: 1em;
}

.nav-breadcrumbs a::before {
  content: " ";
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 27px;
  height: 27px;
  transform: rotate(45deg);
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0;
  top: 6px;
}

.nav-breadcrumbs a:last-child::before {
  display: none;
}

.nav-breadcrumbs a .step-number {
  width: 20px;
  height: 20px;
  border: solid 1px #949494;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.nav-breadcrumbs a.active {
  color: #e73d2f;
}

.nav-breadcrumbs a.active .step-number {
  background: #e73d2f;
  color: #fff;
  border-color: #e73d2f;
}

.multi-form-wrapper .section .form-sub-section {
  background: #fff;
  padding: 20px;
  border-radius: 0 0 5px 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.multi-form-wrapper .section .form-inner-section {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: calc(100vh - 96px);
}

.multi-form-wrapper .section .form-wrapper h3 {
  font-size: 1.3em;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

.multi-form-wrapper .section .form-wrapper p {
  margin: 0;
  padding: 5px 0;
}

.form-box-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.form-box-wrapper .form-box {
  width: calc(50% - 20px);
  margin: 10px;
}

.form-box-wrapper .form-box.full-width {
  width: 100%;
}

.form-box-wrapper .form-box.filled-box {
  padding: 0;
  border: 0;
  border-radius: 0;
}

.form-box-wrapper .form-box .form-label {
  font-size: 15px;
  color: #9c9c9c;
  padding: 5px 5px 0 5px;
}

input[type="date"]::-webkit-datetime-edit {
  color: #9c9c9c;
}

.form-label-error {
  font-size: 11px;
  color: #e42121;
  padding: 5px 5px 0 5px;
}

.form-box-wrapper .form-box .form-value {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.form-box-wrapper .form-box .form-value span {
  max-width: calc(100% - 25px);
}

.form-box-wrapper .form-box .form-value svg {
  width: 17px;
  height: 17px;
  fill: green;
  margin-left: 5px;
}

.form-box-wrapper .form-box .input-box {
  position: relative;
  border: solid 1px #ddd;
  border-radius: 10px;
  padding: 5px;
}

.form-box-wrapper .form-box .input-box:hover {
  border: 1px solid rgb(134, 134, 134);
}

.form-box-wrapper .form-box .input-box input,
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-shadow: 0 0 0 #fff;
  border: 0;
  height: auto;
  width: 100%;
  font-size: 16px;
  padding: 5px;
}

.form-box-wrapper .form-box .input-box input:focus {
  outline: none;
}

.form-box-wrapper .form-box .input-box input:focus,
select:focus {
  outline-offset: -2px;
}

.form-required {
  color: #f23a3c;
  margin-left: 4px;
}

select {
  padding-right: 30px;
}

.down-arrow {
  width: 10px;
  height: 10px;
  fill: #666;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -5px;
  display: flex;
}

.form-buttons-wrapper {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-buttons-wrapper.single-button {
  justify-content: flex-end;
}

.form-buttons-wrapper.otp-button {
  justify-content: flex-start;
}

.form-buttons-wrapper.partial-form-button {
  margin: 10px;
  width: calc(100% - 40px);
  justify-content: flex-start;
  color: white;
}
.form-buttons-wrapper a.sec-btn {
  background: #666;
}

.form-buttons-wrapper a,
.form-buttons-wrapper button {
  appearance: none;
  border: 0;
  background: #e73d2f;
  padding: 0px 40px;
  min-width: 120px;
  border-radius: 5px;
  color: #fff !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 25px;
  justify-content: center;
  font-size: 18px;
}

.form-buttons-wrapper a.table-btn {
  background: #ddd;
  padding: 10px;
  border-radius: 5px;
  color: #fff !important;
  text-decoration: none;
  display: flex;
  width: auto;
  min-width: 0;
  align-items: center;
  height: auto;
  justify-content: center;
}

.form-buttons-wrapper a.table-btn svg {
  width: 20px;
  height: 20px;
  fill: #666;
  margin: 0px !important;
}

.form-buttons-wrapper a svg {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  fill: #fff;
}

.form-buttons-wrapper a.secondary {
  background: #666;
}

.form-buttons-wrapper a.with-icons {
  display: flex;
  align-items: center;
}

.form-buttons-wrapper a.with-icons svg {
  width: 20px;
  height: 20px;
  fill: white;
  margin-right: 10px;
}

.form-buttons-wrapper a.secondary-dim {
  background: #a9a9a9;
}

.form-buttons-wrapper a.disabled {
  cursor: not-allowed;
}

.error {
  border: 1px solid red;
  background-color: #ffe6e6;
}

.form-buttons-wrapper a.secondary svg {
  margin-left: 0px;
  margin-right: 5px;
}

.form-buttons-wrapper a.secondary-dim svg {
  margin-left: 0px;
  margin-right: 5px;
}

.form-wrapper .form-header {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form-wrapper .form-box-wrapper .form-box.filled-box .form-label {
  padding: 0;
  margin-bottom: 1px;
}

.radio-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 5px;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  z-index: 0;
}

.radio-container .checkmark {
  position: relative;
  z-index: 1;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
}

.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.radio-container input:checked ~ .checkmark {
  background-color: #e73d2f;
}

.radio-container .checkmark:after {
  content: "";
  display: none;
}

.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

.radio-container .radio-text {
  padding-left: 5px;
  width: calc(100% - 20px);
}

@media only screen and (max-width: 1020px) {
  .multi-form-wrapper section {
    width: 100%;
    padding-left: 0;
  }
}

@media only screen and (max-width: 550px) {
  .multi-form-wrapper .section .form-wrapper .form-box-wrapper .form-box {
    width: 100%;
  }
}

.form-wrapper .table-outer-wrapper .table-header {
  margin: 20px 0px 10px 0px;
  font-size: 1.1em;
  font-weight: bold;
}

.form-wrapper .table-outer-wrapper .table-wrapper {
  border: solid 1px #dee2e6;
  border-bottom: 0px;
  max-width: 100%;
  overflow: auto;
}

.form-wrapper .table-outer-wrapper .table-wrapper table {
  margin-bottom: 0px;
}

.form-wrapper .table-outer-wrapper .table-wrapper table td {
  text-align: left;
  vertical-align: middle;
}

.form-table-wrapper {
  width: 100%;
  margin: 10px;
}

.form-wrapper .form-sub-heading {
  width: 100%;
  background: #7c0f06;
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}

.apply-button-wrapper {
  margin-top: 10px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 90px);
  align-items: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.not-found-container {
  height: 100vh;
  padding: 20px;
}

.not-found-header {
  font-size: 10rem;
  font-weight: bold;
  margin: 0;
}

.not-found-message {
  font-size: 2rem;
  margin-top: 1rem;
}

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  margin: 5px 0;
  color: #9c9c9c;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  appearance: none;
  z-index: -1;
}

.checkbox-container .checkmark {
  height: 25px;
  width: 25px;
  background-color: #eee;
  position: relative;
  border-radius: 5px;
}

.checkbox-container .checkbox-text {
  width: calc(100% - 25px);
  padding-left: 5px;
}

.checkbox-container :hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #e73d2f;
}

.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 4px;
  width: 7px;
  height: 14px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.first-page.multi-form-wrap-outer {
  background: #fff;
}

.first-page.multi-form-wrap-outer,
.first-page.multi-form-wrap-outer .multi-form-wrapper .section {
  padding: 0;
}

.first-page.multi-form-wrap-outer .multi-form-wrapper .section .form-wrapper {
  background: none;
  border: 0;
  box-shadow: 0 0 0 #fff;
}

.form-submit-response-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
}

.form-submit-response-outer .form-submit-response-wrapper {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #ccc;
  padding: 30px;
  width: 400px;
  max-width: 100%;
}

.form-submit-response-outer .form-submit-response-wrapper p {
  padding: 0;
  margin: 20px 0;
  line-height: 1.5em;
  text-align: center;
  color: #666;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: justify;
}

.form-submit-response-outer .form-submit-response-wrapper .svg-holder {
  margin-bottom: 10px;
  text-align: center;
  line-height: 0;
}

.form-submit-response-outer .form-submit-response-wrapper .svg-holder svg {
  width: 150px;
  height: 150px;
}

.form-submit-response-outer
  .form-submit-response-wrapper
  .form-buttons-wrapper {
  text-align: center;
  justify-content: center;
}

.form-submit-response-outer .form-submit-response-wrapper .loan-id-wrapper {
  margin: 30px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
}
.form-submit-response-outer
  .form-submit-response-wrapper
  .loan-id-wrapper
  .loan-id {
  margin: 5px 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
.form-submit-response-outer
  .form-submit-response-wrapper
  .loan-id-wrapper
  .loan-id-text {
  font-size: 30px;
  line-height: 30px;
  padding: 5px 0;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  font-weight: 600;
}
.form-wrapper {
  width: 100%;
}

.loan-name-header {
  padding-left: 20px;
  color: white;
  font-size: 30px;
}

.welcome-content-outer {
  background: #fff;
  padding: 0px 20px;
}

.dark-theme .welcome-content-outer {
  background-color: rgb(32, 32, 30);
}

.welcome-content-wrapper {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.dark-theme .welcome-content-wrapper {
  background: rgb(48, 50, 55);
  color: white;
}

.welcome-content-wrapper h3 {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.welcome-content-wrapper p {
  padding: 0;
  margin: 5px 0px;
}
.welcome-points {
  margin: 10px 0px;
}

.welcome-points .each-points {
  margin: 5px 0px;
  display: flex;
  align-items: center;
}

.welcome-points .each-points svg {
  width: 17px;
  height: 17px;
  fill: #27d381;
}

.welcome-points .each-points span {
  width: calc(100% - 17px);
  padding-left: 10px;
}

.loan-cards-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.loan-cards-wrapper.first-card {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #ddd;
}

.loan-cards-wrapper .each-cards {
  width: 150px;
  min-height: 150px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 0 5px #ddd;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: center;
}
.loan-cards-wrapper .each-cards:hover {
  box-shadow: 0 0 10px #8d8d8d;
}
.loan-cards-wrapper .each-cards .svg-holder {
  margin-bottom: 15px;
}
.loan-cards-wrapper .each-cards .svg-holder .svg-wrap {
  width: 60px;
  height: 60px;
  border: solid 1px #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}
.loan-cards-wrapper .each-cards .svg-holder .svg-wrap svg {
  width: 30px;
  height: 30px;
  fill: #666;
}
.loan-cards-wrapper .each-cards .selected-div {
  display: none;
  position: absolute;
  top: 7px;
  right: 7px;
}

.loan-cards-wrapper .each-cards:hover .selected-div,
.loan-cards-wrapper .each-cards.active .selected-div {
  display: block;
}
.loan-cards-wrapper .each-cards .selected-div svg {
  width: 25px;
  height: 25px;
  fill: #e73d2f;
}

.dashboard-wrapper {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 90px);
}

.dashboard-wrapper .dashboard-box {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #ddd;
}

.dashboard-steps-wrapper {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-steps-wrapper .step-no {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 1px #666;
}
.dashboard-steps-wrapper .step-no.active {
  background: #e73d2f;
  border: solid 1px #e73d2f;
  color: #fff;
}
.dashboard-steps-wrapper .step-line {
  width: 100px;
  display: flex;
  align-items: center;
}
.dashboard-steps-wrapper .step-line::before {
  content: "";
  width: 100%;
  height: 1px;
  background: #666;
}

.dashboard-wrapper .dashboard-box .form-box-wrapper {
  width: 100%;
}

.top-logo {
  background: rgba(25, 25, 25, 0.9);
  padding: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.top-logo svg {
  width: 200px;
  fill: white;
}

.max-width-container {
  margin-left: auto;
  margin-right: auto;
}

.top-logo .logo-section .top-back-arrow {
  display: none;
}

.top-logo .logo-section .top-back-arrow svg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.login-page .dashboard-wrapper .dashboard-box {
  padding: 30px;
  align-items: flex-start;
}

.login-page .dashboard-wrapper .dashboard-box .login-logo {
  width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
}

.login-page .dashboard-wrapper .dashboard-box .login-logo svg {
  width: 200px;
  height: 53.332px;
  margin-left: -50px;
}

.dashboard-wrapper .dashboard-box p {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.login-page .dashboard-wrapper .dashboard-box p {
  justify-content: flex-start;
}
.login-form {
  padding: 0px 0px 20px 0px;
}

.login-fields {
  padding-bottom: 10px;
}

.login-captcha {
  padding-top: 10px;
}

.login-fields input {
  appearance: none;
  display: block;
  width: 100%;
  padding: 8px;
  border: solid 1px #ddd;
  border-radius: 5px;
  font-size: 17px;
}
.login-box {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .top-logo {
    flex-wrap: wrap;
    background: none;
    padding: 0px;
  }
  .top-logo .logo-section {
    background: rgba(25, 25, 25, 0.9);
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .dashboard-wrapper {
    min-height: auto;
    justify-content: flex-start;
  }
  .top-logo .logo-section .top-back-arrow {
    display: flex;
  }
  .top-logo .button-section {
    display: none;
  }

  .top-logo .header-text-section {
    background-color: #191919;
    padding: 10px 20px;
    width: 100%;
  }

  .loan-name-header {
    padding-left: 0px;
    font-size: 22px;
  }

  .loan-cards-wrapper .each-cards {
    width: calc(50% - 20px);
  }
}

@media only screen and (max-width: 500px) {
  .loan-cards-wrapper .each-cards {
    width: calc(100% - 20px);
  }
  .form-buttons-wrapper a {
    width: 100%;
    margin: 5px 0;
  }
}

@media only screen and (min-width: 500px) {
  .login-page .dashboard-wrapper .dashboard-box {
    width: 450px;
  }
}
@media only screen and (max-width: 800px) {
  .login-page .dashboard-wrapper .dashboard-box {
    width: 100%;
  }
}
